<template>
  <b-overlay :show="isLoading" rounded="lg">
    <h1 v-if="isLoading">Getting Report Data, may take up to 3 minutes</h1>
    <div>
      <b-card title="Expiring Warranty Report">
        <b-modal
          id="promptDownload"
          ref="modal"
          v-model="promptDownload"
          title="Download"
          hide-footer
        >
          <strong v-if="dataToDownload.length <= 0">Loading Data...</strong>
          <strong v-else>Loading data finished, total data is {{ dataToDownload.length }}</strong>

          <div style="display: table; margin: 0 auto;">
            <b-button :disabled="dataToDownload.length < totalRows" variant="primary" style="margin-top: 20px">
              <download-excel :data="dataToDownload" :fields="filter" :name="'expiring-warranty-'+new Date().getTime()+'.xls'" class="download-border">Download</download-excel>
            </b-button>
          </div>
        </b-modal>
        <!-- First block -->
        <b-container fluid >
          
          <b-row >
          <!-- Expiring Warranty -->
            <b-col cols="12"  >
              <b-card >
                <apexchart 
                  type="bar" 
                  height="50%"
                  :options="chartOptionsMonths" 
                  :series="chartSeriesMonths"
                  ></apexchart>
                <b-card-body>
                  <b-row>
                    <b-col cols="12">
                      <span><b>Search by Expiring Warranty:</b></span>
                    </b-col>
                    <b-col cols="6" >
                      <div class="form-group">
                        <label for="monthA">From Month</label>
                        <b-form-select id="monthA" v-model="selectMonthA" :options="monthOptions" size="md" class="w-2" />
                      </div>
                    </b-col>
                    <b-col cols="6" >
                      <div class="form-group">
                        <label for="yearA">From Year</label>
                        <b-form-select id="yearA" v-model="selectYearA" :options="yearOptions" size="md" class="w-2" />
                      </div>
                    </b-col>
                    <b-col cols="6" >
                      <div class="form-group">
                        <label for="monthA">To Month</label>
                        <b-form-select id="monthB" v-model="selectMonthB" :options="monthOptions" size="md" class="w-2" />
                      </div>
                    </b-col>
                    <b-col cols="6" >
                      <div class="form-group">
                        <label for="yearA">To Year</label>
                        <b-form-select id="yearB" v-model="selectYearB" :options="yearOptions" size="md" class="w-2" />
                      </div>
                    </b-col>
                    <b-col cols="6" >
                      <div class="form-group">
                        <label for="productInput">Product Hierarchy</label>
                        <v-select
                          v-model="toFilterProduct"
                          :options="productHierarchy"
                          label="word"
                          :clearable="false"
                          style="margin-top:5px !important;"
                        />
                      </div>
                    </b-col>
                    <b-col cols="6" >
                      <div class="form-group">
                        <label for="modelNo">Model Number</label>
                        <b-form-input v-model="toFilterModel" placeholder="Model No"></b-form-input>
                      </div>
                    </b-col>
                    <b-col cols="12" >
                      <div class="form-group">
                        <b-button @click="doFilter('months')" variant="primary">Search Expiring</b-button>
                      </div>
                      <div class="form-group">
                        <b-button @click="clearFilter" variant="secondary">Reset</b-button>
                      </div>
                      
                    </b-col>
                  </b-row>
                  
                  

                </b-card-body>
              </b-card>
            </b-col>
            <!-- Expiring Filter-->
            <b-col cols="12">
              <b-card>
                <b-card-body>
                  <b-row>
                    <b-col cols="12">
                      <span><b>Result</b></span>
                    </b-col>
                    <b-col cols="12">
                      <b-button @click="openDownloadModal" class="download-btn" variant="primary">Download Excel</b-button>
                    </b-col>
                    <!-- Table block -->
                    <b-col cols="12" class="mt-2">
                      <b-row>
                        <b-col cols="12" class="table-responsive">
                          <b-table :fields="fields" :items="tablePage" show-empty>
                            <!-- <template #cell(generalWarrantyEndDate)="row">
                              {{dates(row.item.generalWarrantyEndDate)}}
                            </template> -->
                          </b-table>
                        </b-col>

                        <b-col cols="12">
                          <b-pagination 
                          :total-rows="dataForTable.length" 
                          :per-page="perPage" 
                          @change="onPageChange" 
                          v-model="currentPage" 
                          style="float: right" />
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
              
                </b-card-body>
              </b-card>
          </b-col>
        </b-row>
      </b-container>

        
      
      
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { mapActions, mapState, mapGetters } from 'vuex';
import axios from '@/axios';
import vSelect from 'vue-select';
import {userAccess,dateFormat} from '@/utils/utils'

export default {
  components: {
    apexchart: VueApexCharts,
    'v-select': vSelect,
  },
  name: 'barchart',
  props: {
    msg: String
  },
  data() {
    return {
      fields: [
        { key: 'itemModel', label: 'Model', filterable: true, sortable: true },
        { key: 'phlvl1', label: 'Product Hierarchy', filterable: true, sortable: true },
        { key: 'barcode', label: 'Barcode', filterable: true, sortable: true },
        { key: 'serial', label: 'Serial', filterable: true, sortable: true },
        { key: 'scanStatus', label: 'Status', filterable: true, sortable: true },
        { key: 'sapDo', label: 'DO', filterable: true, sortable: true },
        { key: 'dealerCode', label: 'Dealer Code', filterable: true, sortable: true },
        { key: 'shippingPoint', label: 'Warehouse' },
        { key: 'warrantyStatus', label: 'Warranty Status', filterable: true, sortable: true },
        { key: 'generalWarrantyEndDate', label: 'General Warranty End Date', filterable: true, sortable: true },
      ],
      
      filter: {
        'Certificate Number' : 'certNo',
        'Item Model' : 'itemModel',
        'Product Hierarchy' : 'phlvl1',
        'Customer Name' : 'customerName',
        'Address' : 'address',
        'Contact Number': 'contactNumber',
        'General Warranty End':{
          field:'generalWarrantyEndDate'
        },
        'Compressor Warranty End':{
          field:'compressorWarrantyEndDate'
        },
        'Sap DO' :'sapDo',
        'Sap SO' :'sapSo'
        
      },
      currentPage: 1,
      perPage: 10,
      toFilterProduct: '',

      selectMonthA:'January',
      selectYearA:2024,
      selectMonthB:'December',
      selectYearB:2024,

      monthOptions: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],

      toFilterProductYears: '',
      tablePh:'',
      tableExp:'',
      
      tableMonthA:'',
      tableMonthB:'',
      toFilterModel: '',
      toFilterModelExp: '',
      tableYears: '',
      totalRows: 1,
      tableLoading: true,
      
      chartOptionsMonths: {
        
      },
      chartSeriesMonths: [],
      downPage:0,
      dataDownload:0,
      inventoryToDownload:[],
      promptDownload: false,
      dataToDownload:[],
      dataForTable:[],
      isLoading: false,
      productHierarchy: [],
      type : "",

      typeToggle:true,
    };
  },
  methods: {
    ...mapActions({
      getAllInventories: 'inventory/getAllInventories',
      getWarrantyReport: 'report/getWarranty',
    }),

    processMetadata(metadata) {
      let { totalData } = metadata;
      this.totalRows = totalData;
      this.tableLoading = false;
    },

    onPageChange(page) {
      this.currentPage=page
    },
    async clearFilter(){
      this.isLoading=true
      this.toFilterProduct=""
      this.toFilterModel=""
      this.type = 'months'
      this.selectMonthA='January'
      this.selectYearA=2024
      this.selectMonthB='December'
      this.selectYearB=2024
      await this.updateChartData();
    },

    async doFilter(type) {
      this.isLoading = true
      this.type = type
      await this.updateChartData();
    },
    async openDownloadModal(){
        this.downPage = 0
        this.inventoryToDownload = []
        this.promptDownload=true;
        let page = 0
        let dataProcess = []
        this.downPage = page  
        this.dataToDownload = this.dataForTable
      },

    async getExpiringCertificatesCountMonths() {

      //all filter valued cannot be empty
      // if(this.toFitlerProduct==''||this.toFilterModel==''){
      //   this.$bvToast.toast(`Filter values cannot be empty`, {
      //     title: "Failed",
      //     variant: "danger",
      //     solid: true,
      //   });
      //   this.isLoading = false
      //   return;
      // }
      const counts = {};
      const today = new Date();
      const currentYear = today.getFullYear();
      const startMonthIndex = this.monthOptions.indexOf(this.selectMonthA);
      const startYear = this.selectYearA
      const endMonthIndex = this.monthOptions.indexOf(this.selectMonthB);
      const endYear = this.selectYearB
      let data;
      var res;

      try{
        res = await this.getWarrantyReport({
        phlvl1: this.toFilterProduct.phNumber,
        model:this.toFilterModel,
        startMonth :startMonthIndex,
        startYear: startYear,
        endMonth :endMonthIndex,
        endYear :endYear
      })
      }catch(err){
        this.$bvToast.toast(`Get Warranty Report Failed, please select filters and try again`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        this.isLoading=false
      }

      
      
      let tableData = res.data
      this.dataForTable=[]
      if (tableData.length>0) {
        console.info('month tableData ',tableData)
        var certificate;
        var dateParse
        var expiryDate;
        var expiryMonthIndex;
        var expiryYear;
        var monthName
        for(var i=0;i<tableData.length;i++){
          if(i%2500==0){
            console.info(i)
          }
          certificate = tableData[i];
          this.dataForTable.push( {
            ...certificate,
            shippingPoint: certificate.shippingPoint == "1001" ? "Daikin" : "Sumitomo"
          })
          //format is DD/MM/YYYY
          dateParse = certificate.generalWarrantyEndDate.split("/");
          expiryDate = new Date(dateParse[2]+"-"+dateParse[1]+"-"+dateParse[0]);
          expiryMonthIndex = expiryDate.getMonth();

          expiryYear= expiryDate.getFullYear();
          monthName = this.getMonthName(expiryMonthIndex);
          counts[monthName+" "+expiryYear] = (counts[monthName+" "+expiryYear] || 0) + 1;
        }
      }
      console.info('counts ',counts)
      return new Promise((resolve,reject)=>{
        resolve(counts)
      })      
    },
    getMonthName(monthIndex) {
      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      return months[monthIndex];
    },

    sortMonths(data) {
      var monthOrder = [];
      var currentYear = new Date().getFullYear();
      var temp
      for(var x = 0;x<=2;x++){
        temp = currentYear+x
        monthOrder.push('January '+temp);
        monthOrder.push('February '+temp);
        monthOrder.push('March '+temp);
        monthOrder.push('April '+temp);
        monthOrder.push('May '+temp);
        monthOrder.push('June '+temp);
        monthOrder.push('July '+temp);
        monthOrder.push('August '+temp);
        monthOrder.push('September '+temp);
        monthOrder.push('October '+temp);
        monthOrder.push('November '+temp);
        monthOrder.push('December '+temp);
      }
      //console.info('monthOrder ',monthOrder)
      return data.sort((a, b) => monthOrder.indexOf(a[0]) - monthOrder.indexOf(b[0]));
    },
    
    async updateChartData() {
      let countsMonths = {}
      
      countsMonths = await this.getExpiringCertificatesCountMonths();
      console.info('countsMonths ',countsMonths)


      countsMonths = Object.entries(countsMonths);
      countsMonths = this.sortMonths(countsMonths);
      //console.info('count',countsMonths)

      this.chartOptionsMonths = {
        chart: {
          type: 'bar',
          toolbar: {
            show: false
          }
        },
        xaxis: {
          categories: countsMonths.map(month => month[0]),
          title: {
            text: 'Months'
          }
        },
        yaxis: {
          title: {
            text: 'Number of Expiring Certificates'
          }
        }
      };
      
      this.chartSeriesMonths = [{
        name: 'Certificates',
        data: countsMonths.map(month => month[1])
      }];

      this.isLoading=false
    },

    async getProductHierarchy(filter) {
      await axios.get(`sales-and-purchase/drcc/phmap`, { params: { ...filter } }).then((response) => {
        this.productHierarchy=response.data.data  
        //this.processMetadata(response.data.metadata)
        return response.data;
      }).catch((e) => {
        console.error(e);
      })
    },
    dates(date) {
      //console.info('date ',date)
      if (date==undefined||date==""){
        return ""
      }
      return dateFormat(date);
    },
  },

  computed: {
    ...mapGetters({
      warranty: 'report/warranty'
    }),
    tablePage(){
      var temp=this.dataForTable;
      if(temp.length<=0){
        return temp
      }
      temp = temp.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
      //splice 

      return temp;
    },
    progressDownload () {
      let downloaded = this.dataToDownload.length
      let dataToDownload = this.totalRows
      return Math.ceil((downloaded/dataToDownload)*(100))
    },
    yearOptions(){
      var curYear=new Date().getFullYear()
      var list = [curYear,curYear+1,curYear+2,curYear+3]
      return list;
    }
  },
  watch: {
    selectMonthA(newVal) {
      this.tableMonthA = newVal;
    },
    selectMonthB(newVal) {
      this.tableMonthB = newVal;
    },
    toFilterProduct(newVal) {
      this.tablePh = newVal;
    },
    toFilterProductYears(newVal) {
      this.tableExp = newVal;
    },
  },
  created() {
    document.title = 'Warranty Report | RSP';
    this.getProductHierarchy({
      page: 1, 
      entry: 500,
    })
  },
};
</script>

<style scoped>
.container {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}

.controls {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.dropdown {
  padding: 10px;
  font-size: 16px;
}

.chart-container {
  margin-top: 20px;
}

</style>